<template>
    <LiefengContent>
        <template v-slot:title>取消记录</template>
        <template v-slot:toolsbarRight>
            <Button type="primary" icon="ios-time-outline" @click="changeActivity" >改期活动时段</Button>
            <Button type="primary" @click="cancelActivity" icon="md-add" >按活动时段取消</Button>
            <Button type="primary" @click="targetActivity" icon="md-add" >生成下一周期报名时段</Button>
            <Button type="error" icon="ios-arrow-back" @click="clckBack">返回</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable :talbeColumns="talbeColumns" :tableData="tableData" :loading="loading" :fixTable="true" :hidePage="true"></LiefengTable>
            <!-- 取消 -->
            <LiefengModal title="取消活动" :value="modalStatus" @input="modalStatusFn" width="600px" height="500px">
                <template v-slot:contentarea>
                    <h3>请勾选需要取消的时段，显示本周期中未开展的活动时段</h3>
                    <CheckboxGroup v-model="timeArr" v-if="modalData.length > 0" style="display: flex; justify-content: space-between; flex-wrap: wrap">
                        <Checkbox style="width: 48%; margin: 10px 0; text-align: center" :label="item.id" v-for="(item, index) in modalData" :key="index">
                            {{ item.operDate }} （{{ item.week }}） {{ item.cycleTime }}
                        </Checkbox>
                    </CheckboxGroup>
                    <div style="text-align: center; height: 383px; line-height: 383px" v-else>
                        <i>暂没有可取消的活动</i>
                    </div>
                    <div style="color: red">注：取消后用户将不可选择该时段报名，若用户已报名，将提示用户本次活动已取消。</div>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="modalStatusFn(false)" style="margin-right: 10px">关闭</Button>
                    <Button type="primary" @click="saveModal" v-if="modalData.length > 0">确定</Button>
                </template>
            </LiefengModal>
            <!-- 改期活动 -->
            <LiefengModal title="改期活动时段" :value="changeStatus" @input="changeStatusFn" width="700px" height="500px">
                <template v-slot:contentarea>
                    <div>
                        <h3>请勾选需要改期的时段，显示本周期中未开展的活动时段</h3>
                        <RadioGroup v-model="changeTime" v-if="changeModalData.length > 0" style="display: flex; justify-content: space-between; flex-wrap: wrap">
                            <Radio style="width: 100%; margin: 10px" :label="item.id" v-for="(item, index) in changeModalData" :key="index">
                                {{ item.operDate }} （{{ item.week }}） {{ item.cycleTime }}
                                <span style="width: 48%; margin: 10px 0 10px 10px" v-if="changeTime == item.id">
                                    <span>更改为：</span>
                                    <DatePicker transfer v-model="operDate" type="date" placeholder="请选择本周期内的日期" style="width: 168px"></DatePicker>
                                    <span>-</span>
                                    <TimePicker
                                        transfer
                                        v-model="cycleTime"
                                        format="HH:mm"
                                        type="timerange"
                                        placement="bottom-end"
                                        placeholder="请选择本周期内的时段"
                                        style="width: 168px"
                                    ></TimePicker>
                                </span>
                            </Radio>
                        </RadioGroup>
                        <div style="text-align: center; height: 362px; line-height: 362px" v-else>
                            <i>暂没有可改期的时段</i>
                        </div>
                        <div style="color: red">注：更改时间只能选择本周期内的时段，改期后，若用户已报名，将提示用户本次活动的更改后的时间。</div>
                    </div>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="changeStatusFn(false)" style="margin-right: 10px">关闭</Button>
                    <Button type="primary" @click="saveChangeModal" v-if="changeModalData.length > 0">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/activityinterval');
import LiefengContent from "@/components/LiefengContent"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"

export default {
    data() {
        return {
            talbeColumns: [
                {
                    title: "开展日期",
                    align: "center",
                    minWidth: 180,
                    key: "operDate",
                },
                {
                    title: "时间段",
                    align: "center",
                    minWidth: 180,
                    key: "cycleTime",
                },
                {
                    title: "操作人",
                    align: "center",
                    width: 180,
                    key: "operName",
                },
                {
                    title: "操作时间",
                    align: "center",
                    minWidth: 180,
                    key: "gmtModified",
                },
                {
                    title: "状态",
                    align: "center",
                    width: 180,
                    key: "status",
                },
            ],
            tableData: [],
            loading: false,
            // 取消活动
            modalData: [],
            modalStatus: false,
            timeArr: [],
            // 改期活动时段
            changeStatus: false,
            changeTime: "",
            changeModalData: [],
            menuCodeObj: {},
            operDate: "",
            cycleTime: "",
        }
    },
    methods: {
        clckBack() {
            var index = parent.layer.getFrameIndex(window.name)
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push({
                    path: "/activityindex",
                    query: { menuId: this.$route.query.menuCode },
                })
            }
        },
        targetStatusFn(status) {
            this.targetArr = []
            this.targetStatus = status
        },
        targetActivity() {
            let now = new Date().getTime()
            if (this.$route.query.endDate - now < 0 || (this.$route.query.endDate - now) / (1000 * 60 * 60 * 24) < 1) {
                this.$Modal.info({
                    title: "温馨提示",
                    content: `<p>该活动于 ${this.$core.formatDate(new Date(Number(this.$route.query.endDate)), "yyyy-MM-dd")} 结束，无法生成下一周期报名时段</p>`,
                    okText: "确定",
                })
                return
            }
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在生成下一周期的报名时段，是否继续</p>",
                okText: "确定",
                cancelText: "取消",
                loading: true,
                onOk: () => {
                    // this.$post('/datamsg/api/pc/information/appointment/generateCyclePeriodRecords',{
                    this.$post("/info/api/pc/information/appointment/generateCyclePeriodRecords", {
                        infoId: this.$route.query.id,
                        ignoreCycle: false,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "生成成功",
                                })
                                this.$Modal.remove()
                                this.hadlePageSize("1", "tableData")
                            } else {
                                this.$Modal.remove()
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Modal.remove()
                            this.$Message.error({
                                background: true,
                                content: "生成下一周期的报名时段失败，请联系管理员处理",
                            })
                        })
                },
            })
        },
        saveModal() {
            if (this.timeArr.length == 0) {
                this.$Message.error({
                    background: true,
                    content: "请选择取消的活动时段",
                })
                return
            }
            // this.$post("/datamsg/api/pc/information/appointment/changePeriod", {
            this.$post("/info/api/pc/information/appointment/changePeriod", {
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                periodIds: this.timeArr.join(","),
                cycleStatus: "1",
                operName: parent.vue.loginInfo.userinfo.realName,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "保存成功",
                        })
                        this.hadlePageSize("1", "tableData")
                        this.modalStatus = false
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "保存失败，请联系管理员处理",
                    })
                })
        },
        saveChangeModal() {
            if (!this.changeTime) {
                this.$Message.error({
                    background: true,
                    content: "请选择需要改期的活动时段",
                })
                return
            }
            if (!this.operDate) {
                this.$Message.error({
                    background: true,
                    content: "请设置改期的活动日期",
                })
                return
            }
            if (!this.cycleTime) {
                this.$Message.error({
                    background: true,
                    content: "请设置改期的活动时段",
                })
                return
            }
            if (this.cycleTime[0] == this.cycleTime[1]) {
                this.$Message.error({
                    background: true,
                    content: "改期的活动开始时间和结束时间不能相同",
                })
                return
            }
            let operDate = this.$core.formatDate(new Date(this.operDate), "yyyy-MM-dd")
            let cycleTime = this.cycleTime[0] + "-" + this.cycleTime[1]

            this.$post("/datamsg/api/pc/information/appointment/changePeriodSetting", {
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                terminal: "2",
                id: this.changeTime,
                operDate: operDate,
                cycleTime: cycleTime,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "保存成功",
                        })
                        this.hadlePageSize("1", "tableData")
                        this.changeStatus = false
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "保存失败，请联系管理员处理",
                    })
                })
        },
        modalStatusFn(status) {
            this.timeArr = []
            this.modalStatus = status
        },
        changeStatusFn(status) {
            this.operDate = ""
            this.cycleTime = []
            this.changeTime = ""
            this.changeStatus = status
        },
        async changeActivity() {
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            })
            await this.hadlePageSize("0", "changeModalData")
            this.changeStatus = true
        },
        async cancelActivity() {
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            })
            await this.hadlePageSize("0", "modalData")
            this.modalStatus = true
        },
        hadlePageSize(cycleStatus, list) {
            if (cycleStatus == 1) this.loading = true //这是取消列表
            this.$get(
                // "/datamsg/api/pc/information/appointment/selectAppointmentPeriodList",
                "/info/api/pc/information/appointment/selectAppointmentPeriodList",
                {
                    informationId: this.$route.query.id,
                    operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    oemCode: parent.vue.oemInfo.oemCode,
                    terminal: "2",
                    cycleStatus: cycleStatus, //取消状态 0 进行中 1 已取消 2 已完成,3.预约时段
                }
            )
                .then(res => {
                    if (cycleStatus == 1) this.loading = false
                    if (cycleStatus == 0 || cycleStatus == 3 || cycleStatus == 2) this.$Message.destroy() //这是按活动时段取消 或 改期时段 或已完成
                    if (res.code == 200 && res.dataList) {
                        res.dataList.map(item => {
                            let week = new Date(item.operDate).getDay()
                            switch (week) {
                                case 0:
                                    item.week = "周日"
                                    break
                                case 1:
                                    item.week = "周一"
                                    break
                                case 2:
                                    item.week = "周二"
                                    break
                                case 3:
                                    item.week = "周三"
                                    break
                                case 4:
                                    item.week = "周四"
                                    break
                                case 5:
                                    item.week = "周五"
                                    break
                                default:
                                    item.week = "周六"
                                    break
                            }
                            item.operDate1 = item.operDate
                            item.operDate = this.$core.formatDate(new Date(item.operDate), "yyyy-MM-dd")
                            item.gmtModified = this.$core.formatDate(new Date(item.gmtModified), "yyyy-MM-dd hh:mm:ss")
                            switch (item.status) {
                                case "0":
                                    item.status = "进行中"
                                    break
                                case "1":
                                    item.status = "已取消"
                                    break
                                case "2":
                                    item.status = "已完成"
                                    break
                                case "3":
                                    item.status = "名额已满"
                                    break
                            }
                        })
                        this[list] = res.dataList
                    } else {
                        if (cycleStatus == 0 || cycleStatus == 3) this.$Message.destroy() //这是按活动时段取消 或 改期时段
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请重试",
                    })
                })
        },
    },
    async created() {
        // 获取functionType/columnCode等
        await this.$get("/old/api/pc/menu/selectColumnByMenuCode", {
            menuCode: this.$route.query.menuCode,
        }).then(res => {
            if (res.code == 200 && res.data) {
                this.menuCodeObj = res.data
            } else {
                this.$Message.error({
                    background: true,
                    content: res.desc,
                })
            }
        })
        this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize,
        })
        this.hadlePageSize("1", "tableData")
    },
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
    },
}
</script>
    
<style scoped lang='less'>
</style>